<template>
    <v-container>
        <h3 class="grey--text text--darken-2"> Documents </h3>

        <p class="subtitle-1 grey--text text--darken-2">
            Retrouvez ici les différents documents relatifs à votre acquisition du lot
            <v-chip color="app-green" class="ma-0 pa-1" small label dark>{{ selectedSale.product.reference }}</v-chip>
            sur le programme
            <v-chip color="app-green" class="ma-0 pa-1" small label dark>{{ selectedSale.program.title }}</v-chip>.
        </p>

        <p class="caption grey--text text--darken-2">
            Sélectionnez la catégorie de document que vous souhaitez consulter.
        </p>

        <v-item-group v-model="tab" mandatory>
            <div class="d-flex justify-space-between flex-wrap" @click="$vuetify.goTo('#docviewer', {duration: 300, easing: 'easeInOutCubic'})">
                <v-item v-slot="{ active, toggle }" value="sale" v-if="getConfig('documents.allowed_documents_types', []).includes('sale')">
                    <v-card @click="toggle" :elevation="active ? 24 : 5" :class="{ 'card-active': active }" class="slide-transition flex-grow-1 mx-4 mb-4" style="min-width: 200px;">
                        <v-card-title class="white--text caption py-2" :class="{ 'app-red' : active, 'app-blue': !active }">
                            Dossier de vente
                        </v-card-title>

                        <v-card-text class="white text--primary py-2 text-center">
                            <v-icon x-large :color="active?'app-red' :'app-blue'"> mdi-folder-key </v-icon>
                            <br>
                            {{ getDocumentsByType('sale').length }} {{ getDocumentsByType('sale').length | plural('document', 'documents') }}
                        </v-card-text>
                    </v-card>
                </v-item>

                <v-item v-slot="{ active, toggle }" value="product" v-if="getConfig('documents.allowed_documents_types', []).includes('product')">
                    <v-card @click="toggle" :elevation="active ? 24 : 5" :class="{ 'card-active': active }" class="slide-transition flex-grow-1 mx-4 mb-4" style="min-width: 200px;">
                        <v-card-title class="white--text caption py-2" :class="{ 'app-red' : active, 'app-blue': !active }">
                            Plans
                        </v-card-title>

                        <v-card-text class="white text--primary py-2 text-center">
                            <v-icon x-large :color="active?'app-red' :'app-blue'"> mdi-floor-plan </v-icon>
                            <br>
                            {{ getDocumentsByType('product').length }} {{ getDocumentsByType('product').length | plural('document', 'documents') }}
                        </v-card-text>
                    </v-card>
                </v-item>

                <v-item v-slot="{ active, toggle }" value="program" v-if="getConfig('documents.allowed_documents_types', []).includes('program')">
                    <v-card @click="toggle" :elevation="active ? 24 : 5" :class="{ 'card-active': active }" class="slide-transition flex-grow-1 mx-4 mb-4" style="min-width: 200px;">
                        <v-card-title class="white--text caption py-2" :class="{ 'app-red' : active, 'app-blue': !active }">
                            Programme
                        </v-card-title>

                        <v-card-text class="white text--primary py-2 text-center">
                            <v-icon x-large :color="active?'app-red' :'app-blue'"> mdi-file-document-multiple-outline </v-icon>
                            <br>
                            {{ getDocumentsByType('program').length }} {{ getDocumentsByType('program').length | plural('document', 'documents') }}
                        </v-card-text>
                    </v-card>
                </v-item>

                <v-item v-slot="{ active, toggle }" value="timetable" v-if="getConfig('documents.allowed_documents_types', []).includes('timetable')">
                    <v-card @click="toggle" :elevation="active ? 24 : 5" :class="{ 'card-active': active }" class="slide-transition flex-grow-1 mx-4 mb-4" style="min-width: 200px;">
                        <v-card-title class="white--text caption py-2" :class="{ 'app-red' : active, 'app-blue': !active }">
                            Appels de fonds
                        </v-card-title>

                        <v-card-text class="white text--primary py-2 text-center">
                            <v-icon x-large :color="active?'app-red' :'app-blue'"> mdi-cash-multiple </v-icon>
                            <br>
                            {{ getDocumentsByType('timetable').length }} {{ getDocumentsByType('timetable').length | plural('document', 'documents') }}
                        </v-card-text>
                    </v-card>
                </v-item>

                <v-item v-slot="{ active, toggle }" value="idk" v-if="getConfig('documents.allowed_documents_types', []).includes('idk')">
                    <v-card @click="toggle" :elevation="active ? 24 : 5" :class="{ 'card-active': active }" class="slide-transition flex-grow-1 mx-4 mb-4" style="min-width: 200px;">
                        <v-card-title class="white--text caption py-2" :class="{ 'app-red' : active, 'app-blue': !active }">
                            Pièces justificatives
                        </v-card-title>

                        <v-card-text class="white text--primary py-2 text-center">
                            <v-icon x-large :color="active?'app-red' :'app-blue'"> mdi-scale-balance </v-icon>
                            <br>
                            {{ getDocumentsByType('idk').length }} {{ getDocumentsByType('idk').length | plural('document', 'documents') }}
                        </v-card-text>
                    </v-card>
                </v-item>

                <v-item v-slot="{ active, toggle }" value="kitlivraison" v-if="getConfig('documents.allowed_documents_types', []).includes('kitlivraison')">
                    <v-card @click="toggle" :elevation="active ? 24 : 5" :class="{ 'card-active': active }" class="slide-transition flex-grow-1 mx-4 mb-4" style="min-width: 200px;">
                        <v-card-title class="white--text caption py-2" :class="{ 'app-red' : active, 'app-blue': !active }">
                            Kit livraison
                        </v-card-title>

                        <v-card-text class="white text--primary py-2 text-center">
                            <v-icon x-large :color="active?'app-red' :'app-blue'"> mdi-key </v-icon>
                            <br>
                            {{ getDocumentsByType('kitlivraison').length }} {{ getDocumentsByType('kitlivraison').length | plural('document', 'documents') }}
                        </v-card-text>
                    </v-card>
                </v-item>

                <v-item v-slot="{ active, toggle }" value="other" v-if="getConfig('documents.allowed_documents_types', []).includes('other')">
                    <v-card @click="toggle" :elevation="active ? 24 : 5" :class="{ 'card-active': active }" class="slide-transition flex-grow-1 mx-4 mb-4" style="min-width: 200px;">
                        <v-card-title class="white--text caption py-2" :class="{ 'app-red' : active, 'app-blue': !active }">
                            Autres
                        </v-card-title>

                        <v-card-text class="white text--primary py-2 text-center">
                            <v-icon x-large :color="active?'app-red' :'app-blue'"> mdi-expand-all-outline </v-icon>
                            <br>
                            {{ getDocumentsByType('other').length }} {{ getDocumentsByType('other').length | plural('document', 'documents') }}
                        </v-card-text>
                    </v-card>
                </v-item>
            </div>
        </v-item-group>

        <v-card id="docviewer" class="pa-5 mt-5 blue-grey lighten-5" flat>
            <v-row v-if="getDocumentsByType(tab).length > 0">
                <v-col cols="12" lg="3" v-for="document of getDocumentsByType(tab)" :key="document.id">
                    <v-card class="fill-height d-flex flex-column">
                        <v-card-text class="text-center flex-grow-1">
                            <v-icon color="red lighten-1"> {{ getDocumentIcon(document) }} </v-icon>

                            <br>

                            <template v-if="document.title">
                                {{ document.title }}
                            </template>
                            <template v-else>
                                <i> [Document sans nom] </i>
                            </template>
                        </v-card-text>

                        <v-divider />

                        <v-card-actions>
                            <p class="caption mb-0">
                                {{ document.date | toDate() }}
                            </p>

                            <v-spacer />

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn :href="getDocumentUrl(document.id, true)" target="_blank" icon small color="primary" v-on="on">
                                        <v-icon> mdi-download </v-icon>
                                    </v-btn>
                                </template>
                                Télécharger le document
                            </v-tooltip>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn :href="getDocumentUrl(document.id)" target="_blank" icon small color="primary" v-on="on">
                                        <v-icon> mdi-open-in-app </v-icon>
                                    </v-btn>
                                </template>
                                Visualiser le document dans un nouvel onglet
                            </v-tooltip>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>

            <v-row v-else>
                <v-col>
                    <h2 class="text-center grey--text"> Aucun document </h2>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import documentsMixin from '../mixins/documents';

export default {
    name: 'Documents',

    mixins: [documentsMixin],

    data: () => ({
        tab: 'sale'
    }),

    methods: {
        getDocumentsByType(type) {
            return this.selectedSale.documents.filter((d) => d.type === type);
        }
    }
};
</script>

<style lang="scss" scoped>
.slide-transition {
    transition: transform 0.5s;
}

.card-active {
    transform: scale(1.1);
}
</style>
