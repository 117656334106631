var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h3',{staticClass:"grey--text text--darken-2"},[_vm._v(" Documents ")]),_c('p',{staticClass:"subtitle-1 grey--text text--darken-2"},[_vm._v(" Retrouvez ici les différents documents relatifs à votre acquisition du lot "),_c('v-chip',{staticClass:"ma-0 pa-1",attrs:{"color":"app-green","small":"","label":"","dark":""}},[_vm._v(_vm._s(_vm.selectedSale.product.reference))]),_vm._v(" sur le programme "),_c('v-chip',{staticClass:"ma-0 pa-1",attrs:{"color":"app-green","small":"","label":"","dark":""}},[_vm._v(_vm._s(_vm.selectedSale.program.title))]),_vm._v(". ")],1),_c('p',{staticClass:"caption grey--text text--darken-2"},[_vm._v(" Sélectionnez la catégorie de document que vous souhaitez consulter. ")]),_c('v-item-group',{attrs:{"mandatory":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('div',{staticClass:"d-flex justify-space-between flex-wrap",on:{"click":function($event){return _vm.$vuetify.goTo('#docviewer', {duration: 300, easing: 'easeInOutCubic'})}}},[(_vm.getConfig('documents.allowed_documents_types', []).includes('sale'))?_c('v-item',{attrs:{"value":"sale"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"slide-transition flex-grow-1 mx-4 mb-4",class:{ 'card-active': active },staticStyle:{"min-width":"200px"},attrs:{"elevation":active ? 24 : 5},on:{"click":toggle}},[_c('v-card-title',{staticClass:"white--text caption py-2",class:{ 'app-red' : active, 'app-blue': !active }},[_vm._v(" Dossier de vente ")]),_c('v-card-text',{staticClass:"white text--primary py-2 text-center"},[_c('v-icon',{attrs:{"x-large":"","color":active?'app-red' :'app-blue'}},[_vm._v(" mdi-folder-key ")]),_c('br'),_vm._v(" "+_vm._s(_vm.getDocumentsByType('sale').length)+" "+_vm._s(_vm._f("plural")(_vm.getDocumentsByType('sale').length,'document', 'documents'))+" ")],1)],1)]}}],null,false,2905092805)}):_vm._e(),(_vm.getConfig('documents.allowed_documents_types', []).includes('product'))?_c('v-item',{attrs:{"value":"product"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"slide-transition flex-grow-1 mx-4 mb-4",class:{ 'card-active': active },staticStyle:{"min-width":"200px"},attrs:{"elevation":active ? 24 : 5},on:{"click":toggle}},[_c('v-card-title',{staticClass:"white--text caption py-2",class:{ 'app-red' : active, 'app-blue': !active }},[_vm._v(" Plans ")]),_c('v-card-text',{staticClass:"white text--primary py-2 text-center"},[_c('v-icon',{attrs:{"x-large":"","color":active?'app-red' :'app-blue'}},[_vm._v(" mdi-floor-plan ")]),_c('br'),_vm._v(" "+_vm._s(_vm.getDocumentsByType('product').length)+" "+_vm._s(_vm._f("plural")(_vm.getDocumentsByType('product').length,'document', 'documents'))+" ")],1)],1)]}}],null,false,2036384439)}):_vm._e(),(_vm.getConfig('documents.allowed_documents_types', []).includes('program'))?_c('v-item',{attrs:{"value":"program"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"slide-transition flex-grow-1 mx-4 mb-4",class:{ 'card-active': active },staticStyle:{"min-width":"200px"},attrs:{"elevation":active ? 24 : 5},on:{"click":toggle}},[_c('v-card-title',{staticClass:"white--text caption py-2",class:{ 'app-red' : active, 'app-blue': !active }},[_vm._v(" Programme ")]),_c('v-card-text',{staticClass:"white text--primary py-2 text-center"},[_c('v-icon',{attrs:{"x-large":"","color":active?'app-red' :'app-blue'}},[_vm._v(" mdi-file-document-multiple-outline ")]),_c('br'),_vm._v(" "+_vm._s(_vm.getDocumentsByType('program').length)+" "+_vm._s(_vm._f("plural")(_vm.getDocumentsByType('program').length,'document', 'documents'))+" ")],1)],1)]}}],null,false,1669797081)}):_vm._e(),(_vm.getConfig('documents.allowed_documents_types', []).includes('timetable'))?_c('v-item',{attrs:{"value":"timetable"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"slide-transition flex-grow-1 mx-4 mb-4",class:{ 'card-active': active },staticStyle:{"min-width":"200px"},attrs:{"elevation":active ? 24 : 5},on:{"click":toggle}},[_c('v-card-title',{staticClass:"white--text caption py-2",class:{ 'app-red' : active, 'app-blue': !active }},[_vm._v(" Appels de fonds ")]),_c('v-card-text',{staticClass:"white text--primary py-2 text-center"},[_c('v-icon',{attrs:{"x-large":"","color":active?'app-red' :'app-blue'}},[_vm._v(" mdi-cash-multiple ")]),_c('br'),_vm._v(" "+_vm._s(_vm.getDocumentsByType('timetable').length)+" "+_vm._s(_vm._f("plural")(_vm.getDocumentsByType('timetable').length,'document', 'documents'))+" ")],1)],1)]}}],null,false,1482561439)}):_vm._e(),(_vm.getConfig('documents.allowed_documents_types', []).includes('idk'))?_c('v-item',{attrs:{"value":"idk"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"slide-transition flex-grow-1 mx-4 mb-4",class:{ 'card-active': active },staticStyle:{"min-width":"200px"},attrs:{"elevation":active ? 24 : 5},on:{"click":toggle}},[_c('v-card-title',{staticClass:"white--text caption py-2",class:{ 'app-red' : active, 'app-blue': !active }},[_vm._v(" Pièces justificatives ")]),_c('v-card-text',{staticClass:"white text--primary py-2 text-center"},[_c('v-icon',{attrs:{"x-large":"","color":active?'app-red' :'app-blue'}},[_vm._v(" mdi-scale-balance ")]),_c('br'),_vm._v(" "+_vm._s(_vm.getDocumentsByType('idk').length)+" "+_vm._s(_vm._f("plural")(_vm.getDocumentsByType('idk').length,'document', 'documents'))+" ")],1)],1)]}}],null,false,3790755143)}):_vm._e(),(_vm.getConfig('documents.allowed_documents_types', []).includes('kitlivraison'))?_c('v-item',{attrs:{"value":"kitlivraison"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"slide-transition flex-grow-1 mx-4 mb-4",class:{ 'card-active': active },staticStyle:{"min-width":"200px"},attrs:{"elevation":active ? 24 : 5},on:{"click":toggle}},[_c('v-card-title',{staticClass:"white--text caption py-2",class:{ 'app-red' : active, 'app-blue': !active }},[_vm._v(" Kit livraison ")]),_c('v-card-text',{staticClass:"white text--primary py-2 text-center"},[_c('v-icon',{attrs:{"x-large":"","color":active?'app-red' :'app-blue'}},[_vm._v(" mdi-key ")]),_c('br'),_vm._v(" "+_vm._s(_vm.getDocumentsByType('kitlivraison').length)+" "+_vm._s(_vm._f("plural")(_vm.getDocumentsByType('kitlivraison').length,'document', 'documents'))+" ")],1)],1)]}}],null,false,417304363)}):_vm._e(),(_vm.getConfig('documents.allowed_documents_types', []).includes('other'))?_c('v-item',{attrs:{"value":"other"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"slide-transition flex-grow-1 mx-4 mb-4",class:{ 'card-active': active },staticStyle:{"min-width":"200px"},attrs:{"elevation":active ? 24 : 5},on:{"click":toggle}},[_c('v-card-title',{staticClass:"white--text caption py-2",class:{ 'app-red' : active, 'app-blue': !active }},[_vm._v(" Autres ")]),_c('v-card-text',{staticClass:"white text--primary py-2 text-center"},[_c('v-icon',{attrs:{"x-large":"","color":active?'app-red' :'app-blue'}},[_vm._v(" mdi-expand-all-outline ")]),_c('br'),_vm._v(" "+_vm._s(_vm.getDocumentsByType('other').length)+" "+_vm._s(_vm._f("plural")(_vm.getDocumentsByType('other').length,'document', 'documents'))+" ")],1)],1)]}}],null,false,2762308690)}):_vm._e()],1)]),_c('v-card',{staticClass:"pa-5 mt-5 blue-grey lighten-5",attrs:{"id":"docviewer","flat":""}},[(_vm.getDocumentsByType(_vm.tab).length > 0)?_c('v-row',_vm._l((_vm.getDocumentsByType(_vm.tab)),function(document){return _c('v-col',{key:document.id,attrs:{"cols":"12","lg":"3"}},[_c('v-card',{staticClass:"fill-height d-flex flex-column"},[_c('v-card-text',{staticClass:"text-center flex-grow-1"},[_c('v-icon',{attrs:{"color":"red lighten-1"}},[_vm._v(" "+_vm._s(_vm.getDocumentIcon(document))+" ")]),_c('br'),(document.title)?[_vm._v(" "+_vm._s(document.title)+" ")]:[_c('i',[_vm._v(" [Document sans nom] ")])]],2),_c('v-divider'),_c('v-card-actions',[_c('p',{staticClass:"caption mb-0"},[_vm._v(" "+_vm._s(_vm._f("toDate")(document.date))+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"href":_vm.getDocumentUrl(document.id, true),"target":"_blank","icon":"","small":"","color":"primary"}},on),[_c('v-icon',[_vm._v(" mdi-download ")])],1)]}}],null,true)},[_vm._v(" Télécharger le document ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"href":_vm.getDocumentUrl(document.id),"target":"_blank","icon":"","small":"","color":"primary"}},on),[_c('v-icon',[_vm._v(" mdi-open-in-app ")])],1)]}}],null,true)},[_vm._v(" Visualiser le document dans un nouvel onglet ")])],1)],1)],1)}),1):_c('v-row',[_c('v-col',[_c('h2',{staticClass:"text-center grey--text"},[_vm._v(" Aucun document ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }